import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { navigate } from '@reach/router'
import { slugifyHeading } from '../utils/slugifyHeading'

import { space, palette, fontFamily, fontSizes, colors } from '../utils/tokens'

const ContentLink = ({
  children,
  slug,
  ...rest
}) => {
  return (
    <div
      css={css({
        '> .active': {
          color: colors.primaryBrand,
          fontWeight: `bold`,
        }
      })}
    >
      <a
        href={`#${slug}`}
        css={css({
          textDecoration: `none`,
          display: `inline-block`,
          position: `relative`,
          fontSize: fontSizes[1],
          lineHeight: 1.25,
          cursor: `pointer`,
          fontFamily: fontFamily.heading,
        })}
        {...rest}
      >
        {children}
      </a>
    </div>
  )
}

const filterHeadings = json => {
  // returns a rich text document but with only headings present
  // we're only showing up to h3
  const headingTypes = [
    'heading-2',
    'heading-3',
  ]

  const headings = json.content.filter(item => {
    return headingTypes.includes(item.nodeType)
  })

  const document = {
    nodeType: 'document',
    content: headings,
  }

  return document
}

const TableOfContents = ({ json }) => {
  let jsonObject
  if(json) {
    jsonObject = JSON.parse(json)
  }
  const headingsDoc = filterHeadings(jsonObject)

  const handleAnchorClick = slug => {
    navigate(`#${slug}`)
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span>{text}</span>,
      [MARKS.ITALIC]: text => <span>{text}</span>,
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        const slug = slugifyHeading(node)
        return (
          <li
            css={css({
              margin: `${space[3]}px 0`,
            })}
          >
            <ContentLink
              slug={slug}
              css={css({
                color: palette.grey[80],
              })}
            >
              {children}
            </ContentLink>
          </li>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const slug = slugifyHeading(node)
        return (
          <li
            css={css({
              margin: `${space[2]}px 0`,
            })}
          >
            <ContentLink
              onClick={() => handleAnchorClick(slug)}
              slug={slug}
              css={css({
                color: palette.grey[80],
                marginLeft: `${space[3]}px`,
              })}
            >
              {children}
            </ContentLink>
          </li>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const slug = slugifyHeading(node)
        return (
          <li
            css={css({
              marginBottom: `${space[2]}px`,
            })}
          >
            <ContentLink
              onClick={() => handleAnchorClick(slug)}
              slug={slug}
              css={css({
                color: palette.grey[80],
                fontSize: fontSizes[0],
                marginLeft: `${space[4]}px`,
              })}
            >
              {children}
            </ContentLink>
          </li>
        )
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        const slug = slugifyHeading(node)
        return (
          <li>
            <ContentLink
              slug={slug}
              css={css({
                color: palette.grey[60],
                marginLeft: `${space[3]}px`,
              })}
            >
              {children}
            </ContentLink>
          </li>
        )
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        const slug = slugifyHeading(node)
        return (
          <li>
            <ContentLink
              slug={slug}
              css={css({
                color: palette.grey[70],
                marginLeft: `${space[3]}px`,
              })}
            >
              {children}
            </ContentLink>
          </li>
        )
      },
    },
  }

  return (
    <nav
      css={css({
        fontFamily: fontFamily.body,
        borderBottom: `1px solid ${palette.grey[40]}`,
      })}
    >
      <h2
        css={css({
          fontFamily: fontFamily.body,
          textTransform: `uppercase`,
          fontSize: fontSizes[0],
          fontWeight: `bold`,
          color: palette.grey[80],
          marginBottom: `${space[4]}px`,
          letterSpacing: `0.075em`,
        })}
      >
        Table Of Contents
      </h2>
      <ul
        css={css({
          listStyle: `none`,
          marginBottom: `${space[4]}px`,
        })}
      >
        {renderRichText({ raw: JSON.stringify(headingsDoc) }, options)}
      </ul>
    </nav>
  )
}

TableOfContents.propTypes = {
  json: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default TableOfContents
