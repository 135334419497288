import React from 'react'
import { css } from 'styled-components'
import { FaRegClock, FaRegCalendarAlt } from 'react-icons/fa'

import {
  space,
  mediaQueries,
  fontSizes,
  fontFamily,
  palette,
} from '../../utils/tokens'

const ArticleByline = ({ publishDate, modifiedDate, timeToRead }) => {
  return (
    <div
      css={css({
        display: `flex`,
        flexDirection: `column`,
        fontFamily: fontFamily.body,
        fontSize: fontSizes[1],
        borderTop: `1px solid ${palette.grey[40]}`,
        borderBottom: `1px solid ${palette.grey[40]}`,
        padding: `${space[4]}px ${space[3]}px`,
        [mediaQueries.lg]: {
          flexDirection: `row`,
        },
      })}
    >
      <div
        css={css({
          display: `flex`,
          alignItems: `center`,
          marginBottom: `${space[3]}px`,
          [mediaQueries.lg]: {
            marginBottom: 0,
            marginRight: `${space[4]}px`,
          },
        })}
      >
        <FaRegCalendarAlt
          css={css({
            marginRight: `${space[2]}px`,
          })}
        />
        <p>
          {modifiedDate || publishDate} <span css={css({ fontStyle: `italic` })}>(Last updated)</span>
        </p>
      </div>
      <div
        css={css({
          display: `flex`,
          alignItems: `center`,
          marginRight: `${space[4]}px`,
        })}
      >
        <FaRegClock
          css={css({
            marginRight: `${space[2]}px`,
          })}
        />
        <span>{timeToRead}</span>
      </div>
    </div>
  )
}

export default ArticleByline
