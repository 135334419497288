import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { css } from 'styled-components'
import Img from 'gatsby-image'
import { FaLongArrowAltLeft, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'

import { space, mediaQueries, mainOffset, fontSizes, fontFamily, palette, breakpoints, letterSpacings } from '../utils/tokens'

import Layout from '../components/Layout'

import Container from '../components/Container'
import SEO from '../components/SEO'

import PostLinks from '../components/PostLinks'
import RichArticle from '../components/RichArticle'
import TableOfContents from '../components/TableOfContents'
import ArticleByline from '../components/Article/ArticleByline'

const BlogTemplate = ({ data, location }) => {

  const {
    title,
    metaTitle,
    metaDescription,
    authors,
    id,
    slug,
    heroImage,
    richTextArticle,
    publishDate,
    publishDateISO,
    fields,
  } = data.contentfulBlog

  const postNode = {
    metaDescription: metaDescription,
    title: metaTitle || title,
    heroImage,
    publishDateISO,
  }

  const hero = {
    title: title,
    backgroundPhoto: heroImage,
  }

  const postIndex = data.allContentfulBlog.edges.find(
    ({ node: post }) => post.id === id
  )

  return (
    <div>
      <Layout>
        <Helmet>
          <title>
            {metaTitle
              ? `${metaTitle} | PrintAWorld`
              : `${title} | PrintAWorld`}
          </title>
        </Helmet>
        <SEO
          postNode={postNode}
          pagePath={`blog/${slug}`}
          customTitle
          postSEO
        />
        <Container
          css={css({
            padding: `${space[6]}px 0`,
            [mediaQueries.lg]: {
              padding: `${space[5]}px 0`,
            },
          })}
        >
          <article>
            <div
              css={css({
                padding: `${space[3]}px ${space[4]}px ${space[0]}px`,
              })}
            >
              <Link
                to="/blog/"
                rel="nofollow"
                css={css({
                  textDecoration: `none`,
                  display: `flex`,
                  alignItems: `center`,
                  fontSize: fontSizes[3],
                  marginBottom: `${space[4]}px`,
                })}
              >
                <FaLongArrowAltLeft
                  css={css({
                    marginRight: `${space[3]}px`,
                  })}
                />
                <span>Blog</span>
              </Link>
              {heroImage && (
                <div
                  css={css({
                    [mediaQueries.lg]: {
                      display: `grid`,
                      gridTemplateColumns: `${space[7]}px 1fr`,
                    },
                  })}
                >
                  <div />
                  <Img
                    fluid={heroImage.fluid}
                    alt={heroImage.title}
                    css={css({ marginBottom: `${space[4]}px` })}
                    loading="eager"
                    fadeIn={false}
                  />
                </div>
              )}
              <div
                css={css({
                  display: `grid`,
                  [mediaQueries.lg]: {
                    gridTemplateColumns: `4fr 1fr`,
                  },
                })}
              >
                <h1
                  css={css({
                    fontWeight: `bold`,
                    [mediaQueries.lg]: {
                      fontSize: fontSizes[5],
                    },
                  })}
                >
                  {hero.title}
                </h1>
                <div />
              </div>
            </div>
            {richTextArticle && (
              <div
                css={css({
                  padding: `${space[4]}px ${space[4]}px`,
                  [mediaQueries.lg]: {
                    padding: `${space[4]}px ${space[4]}px ${space[5]}px`,
                  },
                })}
              >
                <ArticleByline
                  timeToRead={fields.readingTime.text}
                  publishDate={publishDate}
                />
              </div>
            )}
            {richTextArticle ? (
              <div
                css={css({
                  padding: `0 calc(${space[3]}px + ${space[2]}px)`,
                  marginBottom: `${space[5]}px`,
                  [mediaQueries.lg]: {
                    marginBottom: `${space[6]}px`,
                  },
                })}
              >
                <div
                  css={css({
                    [mediaQueries.lg]: {
                      display: `flex`,
                      flexDirection: `row-reverse`,
                    },
                  })}
                >
                  <div
                    css={css({
                      marginBottom: `${space[5]}px`,
                      [mediaQueries.lg]: {
                        width: `25%`,
                        paddingLeft: `${space[4]}px`,
                        marginBottom: 0,
                      },
                    })}
                  >
                    <div
                      css={css({
                        position: `sticky`,
                        top: `${mainOffset}`,
                      })}
                    >
                      <TableOfContents
                        json={richTextArticle.raw}
                        location={location}
                        body={richTextArticle}
                      />
                    </div>
                  </div>
                  <div
                    css={css({
                      [mediaQueries.lg]: {
                        width: `75%`,
                      },
                    })}
                  >
                    <RichArticle
                      body={richTextArticle}
                      location={location}
                    />
                    <SocialMediaSection />
                    {authors && (
                      <AuthorBox
                        name={authors[0]?.name}
                        bio={authors[0]?.bio?.internal?.content}
                        image={authors[0]?.image}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </article>
          <PostLinks
            previous={postIndex.previous && postIndex.previous}
            next={postIndex.next && postIndex.next}
          />
        </Container>
      </Layout>
    </div>
  )
}

const AuthorBox = ({ name, bio, image }) => {
  return (
    <div
      css={css({
        padding: `${space[4]}px 0`,
        borderBottom: `1px solid ${palette.grey[40]}`,
        borderTop: `1px solid ${palette.grey[40]}`,
      })}
    >
      <p
        css={css({
          marginBottom: `${space[2]}px`,
          textTransform: `uppercase`,
          fontWeight: `500`,
          fontSize: fontSizes[0],
          letterSpacing: letterSpacings.normal,
          color: palette.grey[80],
        })}
      >
        About the author
      </p>
      <div
        css={css({
          display: `grid`,
          gridTemplateColumns: `auto 1fr`,
          gridGap: `${space[3]}px`,
        })}
      >
        {image?.fixed && (
          <Img
            fixed={image.fixed}
            alt={image.title}
            css={css({
              borderRadius: `100%`,
            })}
          />
        )}
        <div>
          <h5
            css={css({
              marginBottom: `${space[2]}px`,
              fontSize: fontSizes[2],
            })}
          >
            {name}
          </h5>
          <p
            css={css({
              maxWidth: breakpoints.sm,
              color: palette.grey[70],
              fontStyle: `italic`,
              fontSize: fontSizes[2],
            })}
          >
            {bio}
          </p>
        </div>
      </div>
    </div>
  )
}

const SocialMediaSection = () => {
  const anchorStyle = {
    textDecoration: `none`,
    fontSize: fontSizes[3],
    marginRight: `${space[3]}px`,
  }

  return (
    <div
      css={css({
        padding: `${space[3]}px 0`,
      })}
    >
      <p
        css={css({
          marginBottom: `${space[2]}px`,
          fontSize: fontSizes[2],
          fontFamily: fontFamily.body
        })}
      >
        Visit Our Social Media Pages
      </p>
      <a
        href="https://instagram.com/printaworld3d"
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label="printaworld's instagram page"
        css={css({
          ...anchorStyle,
        })}
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.facebook.com/printaworld"
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label="printaworld's facebook page"
        css={css({
          ...anchorStyle,
        })}
      >
        <FaFacebook />
      </a>
      <a
        href="https://www.linkedin.com/company/printaworld"
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label="printaworld's linkedin page"
        css={css({
          ...anchorStyle,
        })}
      >
        <FaLinkedin />
      </a>
    </div>
  )
}

export default BlogTemplate

export const BlogTemplateQuery = graphql`
  query BlogTemplateQuery($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      id
      slug
      metaDescription {
        internal {
          content
        }
      }
      richTextArticle {
        raw
        references {
          ...RichTextArticleFragment
        }
      }
      fields {
        readingTime {
          text
        }
      }
      authors {
        name
        image {
          title
          fixed(width: 64, height: 64) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(maxWidth: 128, maxHeight: 128) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        bio {
          internal {
            content
          }
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      body: article {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      heroImage: coverPhoto {
        title
        fluid(maxHeight: 600, maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
    }
    allContentfulBlog(
      limit: 1000
      sort: {
        fields: [publishDate, originalPublishDate, createdAt]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          title
        }
        previous {
          title
          coverPhoto {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          fields {
            path
          }
        }
        next {
          title
          coverPhoto {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          fields {
            path
          }
        }
      }
    }
  }
`